import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Layout from '@/layout'

/*const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};*/


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/reviews' 
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/reviews',
    name: 'Review',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'ReviewList',
        component: () => import(/* webpackChunkName: "review" */ '../views/review/List.vue'),
        props: (route) => ({page: Number(route.query.page), count: Number(route.query.count)}),
        meta: {
          title: 'Отзывы'
        }
      },
      {
        path: ':id',
        name: 'ReviewView',
        component: () => import(/* webpackChunkName: "review" */ '../views/review/View.vue'),
        meta: {
          title: 'Просмотр отзыва'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


const whiteList = [
  '/login'
]

router.beforeEach(async (to, from, next) => {

  // set page title
  document.title = getPageTitle(to.meta.title)

  const isLoggedIn = store.getters['user/isLoggedIn']
  if (isLoggedIn) {

    if (to.path === '/login' || to.path === '/registration') {
      next('/')
    } else {
      next()
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    next('/login')
  }
})


function getPageTitle(pageTitle) {
  const title = 'Мобильное приложение'
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}



export default router

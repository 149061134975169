import axios from 'axios'
import qs from 'qs'

const state = {
  reviews: [],
  review: {},
  total: 0,
  filters: {
    shop: null
  },
  loading: true
}

const getters = {
  objectList: state => {
    if (state.objects !== undefined && state.objects.result !== undefined) {
      return state.objects.result.map((object => {
        return {value: object.id, label: object.name}
      }));
    }
    return []
  },
  filter: state => (name) => {
    if (state.filters.hasOwnProperty(name)) {
      return state.filters[name]
    }
    return null
  }
}

const mutations = {
  GET_REVIEWS: (state, reviews) => {
    state.reviews = reviews.result
    state.total = reviews.total
  },
  GET_REVIEW: (state, payload) => {
    state.review = payload
  },
  START_LOADING: (state) => {
    state.loading = true
  },
  STOP_LOADING: (state) => {
    state.loading = false
  },
  DELETE_REVIEW: (state, id) => {
    const removeIndex = state.reviews.findIndex(item => item.id === id)
    if (removeIndex !== -1) state.reviews.splice(removeIndex, 1)
  },
}

const actions = {
  getReviews({ commit, state }, params = {}) {
    commit('START_LOADING')
    return new Promise((resolve, reject) => {
      const filter = state.filters
      params = {...params, filter}
      axios.get('/reviews', {
        params,
        paramsSerializer: p => qs.stringify(p, {arrayFormat: 'comma'})
      }).then(resp => {
        commit('GET_REVIEWS', resp.data)
        resolve(resp.data)
      }).catch(err => {
        console.log(err)
        reject(err.response.data)
      }).finally(() => {
        commit('STOP_LOADING')
      })
    })
  },

  getReview({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/reviews/' + id).then(resp => {
        commit('GET_REVIEW', resp.data)
        resolve(resp.data)
      }).catch(err => {
        console.log(err)
        reject(err.response.data)
      })
    })
  },

  sendReply({ commit }, {reply, id}) {
    return new Promise((resolve, reject) => {
      axios.post('/reviews/' + id, {reply}).then(resp => {
        resolve(resp)
      }).catch(err => {
        console.log(err)
        reject(err.response.data)
      })
    })
  },

  deleteReview({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete('/reviews/' + id).then(resp => {
        commit('DELETE_REVIEW', id)
        resolve(resp.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  },

  changeFilter({state, dispatch}, {key, value}) {
    if (state.filters.hasOwnProperty(key)) {
      state.filters[key] = value
      dispatch('getReviews', {
        filter: {[key]:value}
      })
    }
  },

  togglePublished({commit}, {id, published}) {
    return new Promise((resolve, reject) => {
      axios.post('/reviews/' + id + '/published', {published}).then(resp => {
        resolve(resp.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'
import router from '@/router'

const state = {
  loading: false,
  role: localStorage.getItem('role') || null,
  token: localStorage.getItem('token') || null,
  user: JSON.parse(localStorage.getItem('user')) || {},
}

const getters = {
  isLoggedIn: state => !!state.token,
  user: state => state.user,
  getToken: state => state.token,
  role: state => state.role
}

const mutations = {
  AUTH_SUCCESS(state, token) {
    state.loading = false
    state.token = token
    localStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = token
  },
  LOGOUT(state) {
    state.token = null
  },
  SET_USER(state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  SET_ROLE(state, role) {
    state.role = role
    localStorage.setItem('role', role)
  }
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post('/users/login', user).then(resp => {
        const {token, user} = resp.data
        commit('AUTH_SUCCESS', token)
        commit('SET_USER', user)
        commit('SET_ROLE', user.role)
        resolve(resp)
      }).catch(err => {
        console.log(err)
        reject(err.response.data)
      })
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('LOGOUT')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('role')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
import axios from 'axios'
import qs from 'qs'

const state = {
  shops: [],
  current: {},
  loading: true
}

const getters = {
  shopList: state => {
    if (state.shops !== undefined) {
      return state.shops.map((shop => {
        return {value: shop.id, label: `${shop.city}, ${shop.address}`}
      }));
    }
    return []
  },
}

const mutations = {
  GET_SHOPS: (state, shops) => {
    state.shops = shops
  },
  START_LOADING: (state) => {
    state.loading = true
  },
  STOP_LOADING: (state) => {
    state.loading = false
  }
}

const actions = {
  getShops({ commit }, params = {}) {
    commit('START_LOADING')
    return new Promise((resolve, reject) => {
      axios.get('/shops').then(resp => {
        commit('GET_SHOPS', resp.data)
        resolve(resp.data)
      }).catch(err => {
        console.log(err)
        reject(err.response.data)
      }).finally(() => {
        commit('STOP_LOADING')
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

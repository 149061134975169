<template>
  <div id="app">
    <div class="headerWrapper">
      <header class="header">
          <router-link to="/" class="header-logo">
            <img src="@/assets/logo.png" class="header-logo__image">
          </router-link>
          <nav class="header-profile">
            <el-dropdown trigger="click" @command="handleCommand">
              <div class="el-dropdown-link profile-block">
                <div class="profile-row">
                  <div class="header-profile__name">{{ user.email }} <i class="el-icon-arrow-down el-icon--right"></i></div>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-unlock" command="logout">Выйти</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </nav>
        </header>
      </div>
    <router-view/>
  </div>
</template>
<script>
  export default {
    components: {},
    computed: {
      role: function() {
        return this.$store.getters['user/role']
      },
      user: function() {
        return this.$store.getters['user/user']
      }
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
      },
      handleCommand(command) {
        if (command == 'logout') {
          this.logout();
        } else {
          this.$router.push({name: command});
        }
      },
      logout() {
        this.$store.dispatch('user/logout').then(() => {
          this.$router.push('/login');
        });
      },
    }
  }
</script>
<style lang="scss">
  .headerWrapper {
    position: sticky; 
    top:0; 
    z-index: 100;
    background-color: #fff;
  }

  .menu-shadow {
    box-shadow: 0 0 17px 0px rgba(0, 0, 0, 0.26);
    z-index: 1;
    position: relative;
  }

  .header-profile {
    &__role {
      font-size: 13px;
      font-weight: 400;
      color: rgb(43, 150, 29);
      text-align: right;
      margin-bottom: 2px;
    }
  }

  .profile-block {
    display: flex;
    align-items: center;
    outline: none;
  }

  .profile-row {
    margin-right: 0.8rem;
  }


  .header {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;


        box-sizing: border-box;
        border-bottom: 1px solid #dcdfe6;


    .el-dropdown-link {
      cursor: pointer;
      font-size: initial;
      font-weight: 500;
      color: #000;
    }
    .el-icon-arrow-down {
      font-size: 16px;
      font-weight: bold!important;
      color: #000;
    }
  }

  .header-menu {
    display: flex;
    a {
      text-decoration: none;
      color: #000;
      padding: 0 2rem;
      font-weight: 500;
      position: relative;

      &.router-link-active, &:hover {
        &:after {
          content: "";
          position: absolute;
          bottom: -22px;
          display: block;
          height: 2px;
          background: #2c7cff;
          left: 1rem;
          right: 1rem;
          border-top-left-radius: 5rem;
          border-top-right-radius: 5rem;
        }
      }

    }
  }

  .header-logo {
    text-decoration: none;
    
    &__image {
      height: 42px;
    }

    &__text {
      color: rgba(0, 0, 0, 0.8);
      font-weight: 600;
      font-size: 14px;
    }
  }




</style>
import Vue from 'vue'
import Axios from 'axios'
import Element from 'element-ui'

import VueMoment from 'vue-moment'
import moment from 'moment'
import 'moment/locale/ru'
Vue.use(VueMoment, {moment})


import router from './router'
import store from './store'

import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

locale.use(lang)
Vue.use(Element)


Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = ((env) => {
  return env === 'production' 
         ? 'https://push.feedback24.ru/api/' 
         : 'https://push.feedback24.ru/api/'
})(process.env.NODE_ENV)


const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}


Vue.prototype.$http.interceptors.response.use(null, async (error) => {
  const resErrors = [
    'Token expired', 
    'Invalid token', 
    'No token provided'
  ]
  
  if (resErrors.includes(error.response.data)) {
    await store.dispatch('user/logout')
    router.push('/login')
  }

  return Promise.reject(error)
})



import 'normalize.css/normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/app.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h('router-view')
}).$mount('#app')
